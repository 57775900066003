export const formatMaritalStatus = (maritalStatus: string) => {
  const maritalStatusCases: { [key: string]: string } = {
    single: 'Solteira/o',
    married: 'Casada/o',
    widowed: 'Viúva/o',
    divorced: 'Divorciada/o',
    stable_union: 'União Estável'
  }

  return maritalStatusCases[maritalStatus] ?? ''
}
